<template>
    <div class="section">
        <page-title :title="title" class="ml-2"></page-title>
        <b-container>
            <b-card>
                <spinner v-if="refreshData"></spinner>
                <custom-table
                    v-if="!refreshData"
                    :tableConfig="tableConfig"
                    :fields="fields"
                    :items="items"
                    :totalAmount="totalAmount"
                    :page="page"
                ></custom-table>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import InputFilterEvents from '@/views/client/InputFilterEvents';
import CustomTable from '@/components/shared/table/CustomTable';
import { EVENTS } from '@/constants/client/events';
import { QuotationRepository } from '@/repositories';
import { TABLE } from '@/constants';
import moment from 'moment';
import Spinner from '@/components/shared/element/Spinner';
import PageTitle from '@/layouts/components/PageTitle.vue';

export default {
    name: 'ClientInvoiceTreatedQuote',
    extends: InputFilterEvents,
    components: {
        PageTitle,
        CustomTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('client.pages.treatedQuotation.title'),
            refreshData: true,
            tableConfig: {
                sortBy: 'ht_amount',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                displayCount: true
            },
            fields: [
                {
                    key: 'id',
                    label: this.$i18n.t('client.pages.treatedQuotation.labels.quotation_id'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'date',
                    label: this.$i18n.t('client.pages.treatedQuotation.labels.date'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'state',
                    label: this.$i18n.t('client.pages.treatedQuotation.labels.state'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'description',
                    label: this.$i18n.t('client.pages.treatedQuotation.labels.description'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'site',
                    label: this.$i18n.t('client.pages.treatedQuotation.labels.site'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ht_amount',
                    label: this.$i18n.t('client.pages.treatedQuotation.labels.htAmount'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'download',
                    label: this.$t('client.pages.treatedQuotation.labels.download'),
                    sortable: false,
                    class: 'text-center'
                },
                {
                    key: 'actions',
                    label: this.$t('client.pages.treatedQuotation.labels.actions'),
                    sortable: false,
                    class: 'text-center'
                }
            ],
            items: [],
            totalAmount: null,
            page: TABLE.PAGE.CLIENT.TREATED_QUOTATION.ID
        };
    },
    created() {
        this.getData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_FILTER_CHANGE, () => {
            this.getData();
        });
    },
    methods: {
        getData() {
            this.refreshData = true;
            QuotationRepository.getTreatedQuotations(this.getFilters())
                .then(response => {
                    this.refreshData = false;
                    this.items = response.data.data;
                    this.items.map(element => {
                        if (element.approved_at) {
                            element.state = this.$i18n.t('client.pages.treatedQuotation.tableContent.accept', {
                                by: element.approved_by.name,
                                date: moment(element.approved_at).format('DD/MM/YYYY')
                            });
                            element._cellVariants = { state: 'success' };
                        } else if (element.canceled_at) {
                            element.state = this.$i18n.t('client.pages.treatedQuotation.tableContent.cancel', {
                                by: element.canceled_by.name,
                                date: moment(element.canceled_at).format('DD/MM/YYYY')
                            });
                            element._cellVariants = { state: 'danger' };
                        } else {
                            element.state = '';
                        }
                    });
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        }
    }
};
</script>
